.card-container {
    margin-top: -40px;
    
    position: relative;
   
    
    
    
  }
  .BtnCtrl{
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    gap: 15px;
  }
  .custom-prev, .custom-next {
    width: 40px;
    height: 40px;
    background:none;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
   border: none;
    stroke-opacity:"0.5";
    color: #fff;
    z-index: 2;
    
   
  }
  
  .custom-prev {
    left: 10px;
  }
  
  .custom-next {
    right: 10px;
  }
  
  /*  */
  .cardFlip{
    margin-left: 0px;
  }
  .slider{
    height: 387px;
    width:1050px;
    display: flex;  
  }
  .slick-list{
    height:fit-content;

    
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  
  
  .card {
    width: 410px;
    height: 385px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border: none;
    cursor: pointer;
    background-color: white; 
    position: relative;
   
    
   
    /* perspective: 1000px; */
  }
  .cardHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 20px;

        h1{
            font-size: 20px;
            font-weight: bolder;
            padding: 0;
        }
        img{
            height: 20px;
            width: 20px;
        }
  }
  .cardBackMain{
    margin-inline: 10px;
    p{
        padding-top: 0px;
        font-size: 12px;
        text-align: justify;
        line-height: 20px
        
    };
  }
 
  .card-front {
    background-color: #222222;
    border-radius: 5px;
    padding: 0;
    color: #fff;
    
  }
  .card-back {
    background-color: #222222;
    border-radius: 5px;
    padding: 0;
    color: #fff;
  }
  .front-style-1 {
    background-image: url(multiplecardimg/ad.png);
   background-size: cover;
  
    
  }
  .front-style-2 {
    background-image: url(multiplecardimg/wd.png);
   background-size: cover;
    
  }
  .front-style-3 {
    background-image: url(multiplecardimg/wd.png);
   background-size: cover;
    
  }
  .fp{
    p{
        font-weight: 1000;
        font-size: 12px;
    }
  }

  .fpbtns{
    gap: 10px;
    display: flex;
    margin-top: 8px;
}

.fpbtn{
    padding: 7px;
    border-radius: 50px;
    padding-inline: 10px;
    font-size: 10px;
    background-color: #73737354;
    color: #fff;
    border: none;

    span{
        color: #919191;
    }
}


   
  .btnOpenFormm{
    width: 395px;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    font-weight: 900;
    display: flex;
    align-content: center;
    justify-content: center;
    .btnOpenForm{
      color: black;
      font-size: 14px;
    }
    }  
    @media only screen and (min-width: 768px) and  (orientation: portrait){
      .slider{
        height: 430px;
        width:840px;
        display: flex;
        margin-top: px;
        
        
      }
      .card-container{
       height: fit-content;
       margin-top: 200px;
      }
     
      .card {
        width: 320px;
        height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        background-color: #222222;
        background-size: cover;
        background-position: center ;
        background-repeat: no-repeat;
        /* perspective: 1000px; */
      }
      .slick-list{
        height:fit-content;

        
      }
      .slick-track {
        display: flex;
        align-items: center;
      }
      .BtnCtrl{
        display: flex;
        justify-content: end;
       padding-bottom: 20px;
        gap: 20px;
        margin-left:210px ;
        z-index: 2000000;
        width: 70%;
        margin-top: 80px;
      }
      .fp{
        p{
            font-weight: 1000;
            font-size: 8px;
        }
      }
    
      .fpbtns{
        gap: 10px;
        display: flex;
        margin-top: 8px;
    }
    
    .fpbtn{
        
        padding: 7px;
        border-radius: 50px;
        padding-inline: 10px;
        font-size: 8px;
        background-color: #73737354;
        color: #fff;
        border: none;
    
        span{
            color: #919191;
        }
    }
    
    
       
      .btnOpenFormm{
        width: 420px;
        border-radius: 10px;
        padding: 10px;
        margin-top: 12px;
        font-weight: 900;
        }  

        .cardHeader {
        
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0px;
            padding-bottom: 0px;
            
        
                h1{
                    font-size: 15px;
                    font-weight: bolder;
                    padding: 0;
                    width: fit-content;
                }
                img{
                    height: 14px;
                    width: 14px;
                    margin-left: 0px;
                }
          }
          .cardBackMain{
            width: 290px;
            margin-inline: 20px;
            p{
                padding-top: 10px;
                font-size: 10.5px;
                text-align: justify;
                line-height: 18px;
               span{
                display: none;
               } 
            }
          }
          .btnOpenFormm{
            width: 100%;
            border-radius: 10px;
            padding: 6px;
            font-size: 12px;
            margin-top: 12px;
            font-weight: 900;
            } 
    }

  @media screen and (max-width: 475px){
      
    
    .slider{
        height: 430px;
        width:300px;
        display: flex;
        
        
        
      }
      .card-container{
       height: 900px;
       
      }
     
      .card {
        width: 320px;
        height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        background-color: #222222;
        background-size: cover;
        background-position: center ;
        background-repeat: no-repeat;
        /* perspective: 1000px; */
      }
      .slick-list{
        height:fit-content;

        
      }
      .slick-track {
        display: flex;
        align-items: center;
      }
      .BtnCtrl{
        display: flex;
        justify-content: end;
       padding-bottom: 20px;
        gap: 40px;
        margin-left:70px ;
        z-index: 2000000;
        width: 70%;
        margin-top: 80px;
      }
      .custom-prev, .custom-next {
        width: 10px;
        height: 10px;
        background:none;
        border-radius: 50%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #575757;
        stroke-opacity:"0.5";
        color: #fff;
        z-index: 2;
        
       
      }
      .fp{
        p{
            font-weight: 1000;
            font-size: 8px;
        }
      }
    
      .fpbtns{
        gap: 10px;
        display: flex;
        margin-top: 8px;
    }
    
    .fpbtn{
        
        padding: 7px;
        border-radius: 50px;
        padding-inline: 10px;
        font-size: 8px;
        background-color: #73737354;
        color: #fff;
        border: none;
    
        span{
            color: #919191;
        }
    }
    
    
       
      .btnOpenFormm{
        width: 420px;
        border-radius: 10px;
        padding: 10px;
        margin-top: 12px;
        font-weight: 900;
        }  

        .cardHeader {
        
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0px;
            padding-bottom: 0px;
            
        
                h1{
                    font-size: 15px;
                    font-weight: bolder;
                    padding: 0;
                    width: fit-content;
                }
                img{
                    height: 14px;
                    width: 14px;
                    margin-left: 0px;
                }
          }
          .cardBackMain{
            width: 290px;
            margin-right: 30px;
            p{
                padding-top: 10px;
                font-size: 10px;
                text-align: justify;
                line-height: 18px;
               span{
                display: none;
               } 
            }
          }
          .btnOpenFormm{
            width: 280px;
            border-radius: 10px;
            padding: 6px;
            font-size: 12px;
            margin-top: 12px;
            font-weight: 900;
            } 
            .front-style-1 {
                background-image: url(multiplecardimg/wdresponsive.png);
               background-size: contain;
               background-position: center ;
              
                
              }
              .front-style-2 {
                background-image: url(multiplecardimg/wdresponsive.png);
               background-size: cover;
               background-position: center ;
                
              }
              .front-style-3 {
                background-image: url(multiplecardimg/wdresponsive.png);
               background-size: cover;
               background-position: center ; 
              }
              .slick-slider {
                height: fit-content;
              }
    
  }