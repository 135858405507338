.stat{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: fit-content;
}
.statLeft{
    height: auto;
    border-radius: 20px;
    border: 1px solid #a9abae85;
    background-color: #3C3B3B;
    color: white;
    box-sizing: border-box;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.statCenter{
    height: auto;
    border-radius: 20px;
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.statLeft div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.statLeft h1{
    font-family: PoppinsSemiBold;
    width: 100%;
}
.statLeft p{
    font-family: "Silka";
    font-size: 16px;
    width: 80%;
}
.CenterTop{
    display: flex;
    gap: 5px;
    width: 100%;
}
.CenterBottom{
    background-color: #1E1E1E;
    display: flex;
    align-items: center;
    gap: 55px;
    padding: 0 55px;
    border-radius: 20px;
    border: 1px solid #a9abae85;
}
.CenterBottom p{
    font-family: Poppins;
}
.CenterBottom img{
    width: 120px;
}
.topRight{
    position: relative;
}
.conDiv{
    position: absolute;
    width: 100%;
    padding: 10px 0;
    top: 1%;
}
.conDivFlex{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.conH1{
    font-family: 'PoppinsBold';
}
.conImg{
    width: 30px;
}
.conP{
    font-family: 'Poppins';
    font-size: 16px;
}
.firstImage{
    width: 145px;
    margin-right: 5px;
}
.secondImage{
    height: 145px;
}
.thirdImage{
    width: 300px;
    height: 295px;
}
@media only screen and (min-width: 768px) and  (orientation: portrait){
    .stat{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        height: fit-content;
        margin-bottom: 3rem;
    }
    .statLeft{
        flex-direction: row;
        padding: 0 0 20px 20px;
        gap: 100px;
        
    }
    .CenterTop{
        flex-direction: column;
    }
    .firstImage{
        width: 210px;
        margin-right: 5px;
    }
    .secondImage{
        height: 210px;
    }
    .CenterBottom{
        background-color: #1E1E1E;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px 25px;
        border-radius: 20px;
        border: 1px solid #a9abae85;
    }
    .CenterBottom p{
        font-family: Poppins;
        font-size: 20px;
    }
    .CenterBottom img{
        width: 150px;
    }
    .topRight{
        position: relative;

        img{
            width: 100%;
        }
    }
    .conDiv{
        display: flex;
        flex-direction:column ;
        gap:20px;
        
    }
    .conH1{
        font-size: 30px;
    }
    .conDivmain{
       
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        font-size: 25px;
        img{
            width: 40px;
        }
    }
    .conP{
        font-family: 'Poppins';
        font-size: 19px;
    }
}
@media screen and (max-width: 475px){
    .stat{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        height: fit-content;
        margin-bottom: 3rem;
    }
    .statLeft{
        flex-direction: row;
        padding: 0 0 10px 10px;
    }
    .CenterTop{
        flex-direction: column;
    }
    .firstImage{
        width: 110px;
        margin-right: 5px;
    }
    .secondImage{
        height: 110px;
    }
    .CenterBottom{
        background-color: #1E1E1E;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px 5px;
        border-radius: 20px;
        border: 1px solid #a9abae85;
    }
    .CenterBottom p{
        font-family: Poppins;
        font-size: 13px;
    }
    .CenterBottom img{
        width: 80px;
    }
}