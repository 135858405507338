@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


p {
  transition: 0.3s ease-out;
  transition-timing-function: linear;
  font-size: 20px;
}
h1{
  font-family: 'Poppins';
  font-size: 3.3rem;
  width: 60%;
  text-align: center;
  font-weight: 500;
  line-height: 1;
  padding: 1.5rem 0 0 0;
  transition: 0.3s ease-out;
  transition-timing-function: linear;
}
.italics{
  font-weight: 700;
  font-style: italic;
}
.factsContainer{
  display: grid;
  justify-content: flex-end;
  padding: 0 1rem;
  position: absolute;
  left: 63%;
  top: 12%;
  z-index: -1;
}
.factsHead{
  width: 25vw;
  height: 17vh;
  display: flex;
  align-items: last baseline;
  justify-content: center;
  padding-bottom: 8px;
}
.factBtn{
  background-color: rgba(0, 0, 0, 0.25);
  border: #fff solid 1px;
  width: 55%;
  padding: 0.7rem 0.7rem;
  border-radius: 50px;
  cursor: pointer;
  margin-left: -10px;
}
.tech{
  font-size: 15px;
}
.facts{
  border-radius: 10px;
  width: 25vw;
  background-color: rgba(115, 115, 115, 0.33);
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 2rem;
  display: none;
  transition: all 1.5s;
    transition-delay: 1s;
}
.abs{
    display: block;
    left: 62%;
    position: absolute;
    top: 30.5%;  /* 312px;*/
    z-index: 10;
    transition: all 1.5s;
    transition-delay: 1s;
}
.factBtn .abs:hover{
  display: block;
}
/* Inner Text and Head And Button */
.inner{
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  justify-content: space-between;
  align-items: center;
}
.backgroundBar1{
  position: absolute;
  right: 0;
  top: 39px;
  z-index: -1;
  width: 26.3%;
}
.backgroundBar{
  position: absolute;
  z-index: -1;
  height: 81%;
  width: 86%;
}
.inner2{
  padding: 0 1rem;
  display: flex;
  width: 90%;
  flex-direction: column;
  margin-top: 0rem;
}
.textArea{
  text-align: center;
  margin-top: 0rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.headSubtext{
  width: 60%;
  padding: 1rem 0 0 0;
  text-transform: capitalize;
}
.headSubtext2{
  width: 40%;
  font-size: 18px;
  padding: 15px 0 0 0;
  color: #cacacaf3;
  font-weight: 300;
}
.gate{
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  align-items: first baseline;
}
.container{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease all 0.5s;
  margin-top: -1rem;
  margin-left: 1rem;
}
.circle1{
  background-color: #BA0000;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: -2rem;
  transition: ease all 0.5s;
}
.circle1.active{
  width: 180px;
  margin-right: -9.5rem;
  transition: ease all 0.5s;
}
.joinBtn{
  display: flex;
  align-items: center;
  margin-left: 3rem;
}
.btnOpenForm{
  font-size: 15px;
  cursor: pointer;
}
.aboutContainer{
  padding: 0;
  position: absolute;
  left: 9.4%;
  bottom:15.5%;
}
.about{
  border-radius: 10px;
  width: 25vw;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 2rem 1rem;
  display: block;
  transition: 0.5s;
}
.abouthidden{
  border-radius: 10px;
  width: 20vw;
  height: 0;
  overflow: hidden;
  background: none;
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 2rem 1rem;
  display: block;
  font-size: 1px;
  transition: 0.5s;
}
.aboutHead{
  background-color: rgba(115, 115, 115, 0.45);
  height: 6vh; 
  justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px 10px 0 0;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
.gate p{
  font-size: 16px;
}
.App-link {
  color: #61dafb;
}
#aboutText{
  overflow: hidden;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#aboutText p{
  width: 95%;
  font-size: 16px;
  font-family: 'Silka';
  line-height: auto;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 475px){
  .backgroundBar1{
    position: absolute;
    right: 0;
    top: 90px;
    z-index: -1;
    width: 75%;
    display: none;
  }
  h1{
    font-size: 1.8rem;
    width: 100%;
    padding: 1rem 0 0 0;
  }
  .inner{
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
  }
  .headSubtext{
    font-size: 15px;
    width: 90%;
    display: none;
  }
  .headSubtext2{
    font-size: 14px;
    width: 90%;
  }
  .gate img{
    width: 20px;
  }
  .gate p{
    font-size: 14px;
  }
  .factsContainer{
    display: none;
  }
  .btnOpenForm{
    font-size: 14px;
  }
  .aboutContainer{
    padding: 0;
    position: relative;
    left: 0;
    bottom: 0;
    width: 95%;
    margin: 0;
  }
  .aboutHead{
    width: 100%;
  }
  .aboutHead h4{
    font-size: 14px;
  }
  #aboutText{
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  #aboutText p{
    width: 90%;
    font-size: 13px;
    font-family: 'Silka';
    line-height: 1.5;
  }

  
}
