.welcome{
    height: 100%;
    width: 100%;

}
.innerrTop{
    width: 100%;
    display: flex;
    align-items: center;
}
.innerrTopRight{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.innerr{
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }
.circleLong{
  background-color: #BA0000;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: -2rem;
  transition: ease all 0.5s;
}
.circleLong.active{
  width: 220px;
  margin-right: -12rem;
  transition: ease all 0.5s;
}
.welcomeH1{
    font-family: 'Poppins';
    font-size: 2.5rem;
    width: 60%;
    text-align: center;
    font-weight: 500;
    line-height: 1;
    padding: 1.5rem 0 0 0;
    transition: 0.3s ease-out;
    transition-timing-function: linear;
  }
  .italic{
    font-weight: 700;
    font-style: normal;
    font-family: 'PoppinsBold';
  }
  .middle{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .middleLeft{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(115, 115, 115, 0.3);
    margin-right: 2rem;
  }
  .imageContained{
    border-radius: 10px;
    margin-bottom: 5px;
    overflow: hidden;
  }
  .middleLeft p{
    font-size: 14px;
  }
  .middleCenter{
    width: 70%;
  }
  .middleCenter p{
    text-align: left;
    font-size: 16px;
  }
  .middleRight{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .middleRight p{
    font-size: 14px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .middleCenter p:hover{
    transform: scale(1);
  }
  .seyi{
    width: 210px;
    padding-bottom: 0;
    transition: all 380ms ease-out;
  }
  .seyi:hover {
    transform: scale(1.2);
    transition: all 380ms ease-in;
  }
  .backbutton{
    width: 40px;
  }

/* Define CSS animation for sliding in from the right */
@keyframes slideInFromRight {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Define CSS animation for sliding out to the left */
  @keyframes slideOutToLeft {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  
  .slide-in {
    animation: slideInFromRight 0.8s ease-in-out forwards;
  }
  
  .slide-out {
    animation: slideOutToLeft 0.8s ease-in-out forwards;
  }
  .containerr{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease all 0.5s;
  margin-top: -1rem;
  margin-left: 1rem;
}
  .joinBtnn{
  display: flex;
  align-items: center;
  margin-left: -2rem;
}
@media only screen and (min-width: 768px) and  (orientation: portrait){

  .middle{
    padding: 0 0.5rem;
    gap: 80px;
    display: flex;
   flex-direction: column;
    margin-top: 80px;
    align-items: center;
    
  }
  .imageContained{
    border-radius: 10px;
    margin-bottom: 5px;
    overflow: hidden;
    width: 250px;
    img{
      width: 230px;
    }
  }
  .middleCenter{
    width: 90%;
  }
  .middleCenter p{
    text-align: center;
    font-size: 18px;
    line-height: 30px;
  }
  .middleRight p{
    font-size: 15px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .containerr{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease all 0.5s;
    margin-top: 0;
    margin-left: 1rem;
  }
}
  @media screen and (max-width: 475px){
    p:hover{
      transform: scale(1);
    }
    .welcomeH1{
      font-family: 'Poppins';
      font-size: 1.2rem;
      width: 100%;
      text-align: center;
      font-weight: 500;
      line-height: auto;
      padding:  0;
      transition: 0.3s ease-out;
      transition-timing-function: linear;
    }
    .backbutton{
      width: 30px;
      margin-right: 10px;
    }
    .innerrTopRight{
     
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .innerrTop{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1rem;
    margin-top: 105px;
}
.middle{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.seyi{
  width: 160px;
  padding-bottom: 5px;
}
h3{
  font-size: 16px;
}
.innerr{
  justify-content: center;
  padding: 0;
  overflow-x: scroll;
}
.middleLeft{
  padding: 10px 20px;
  margin: 0;
}
.middleLeft p{
  font-size: 12px;
  padding-top: 5px;
  text-align: center;
}
.middleCenter p{
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}
.middleCenter{
  width: 100%;
}
.dis{
  display: none;
}
.circleLong{
  width: 220px;
  margin-right: -12rem;
}
.containerr{
  margin: -1rem 0 0 0;
  padding: 0;
  width: fit-content;
}
.joinBtnn{
  display: flex;
  align-items: center;
  margin: 0 2rem 10px 0;
}
  }