
.serviceContainer{
    margin-bottom:90px;
    height: fit-content;
    align-items: center;


}
.a998{
  display: none;
}
.innerry{
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}
.innerrTopp{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.innerrTopRight{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.containerrr{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease all 0.5s;
    margin-top: -3.5rem;
    margin-left: 1rem;
  }
  @media only screen and (min-width: 768px) and  (orientation: portrait){
    .innerry{
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      margin-top: 0rem;
      justify-content:center;
      align-items: center;
      height: inherit;
    }
    .a998{
      display: flex;
      text-align: center;
      width: 100%;
      margin-top:170px ;
      line-height: 45px;
      font-size: 23px;
      margin-left: 0px;
      margin-bottom: -150px;
      
      p{
        margin-inline: 50px;
        text-align: justify;
        font-size: 23px;
        line-height: 55px;
      }
    }
    .serviceContainer{
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
    }
  }
  @media screen and (max-width: 475px){
    .serviceContainer{
      
        margin-bottom:80px;
        height: 390px;
        overflow: hidden;
        z-index: 10000000;
       
    }
    /* .innerrTopp{
      margin: 0;
      padding: 0;
  } */
  .a998{
    display: none;
  }
  }