/* Navbar.css */
.sideNav {
    position: fixed;
    top: 0;
    right: -100%;
    background-image: url(../img/sideNav1.png);
    background-blend-mode: multiply;
    background-color: #686767f5;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100dvh;
    width: 35%;
    z-index: 2;
    padding: 30px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: right 0.5s ease;
  }
  
  .sideNav.open {
    right: 0;
  }
  
  .sideNavTop {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .sideNavTop img{
    width: 50px;
  }
  .sideNavBottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    padding: 15px 0;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.295);
    border-bottom: 1px solid rgba(255, 255, 255, 0.295);
    text-decoration: none;
  }
  
  .sideNavBottom.active {
    border-bottom: 1px solid white;
    color: white;
  }
  
  .sideNavBottom h2 {
    font-family: "PoppinsBold";
  }
  
  @media screen and (max-width: 475px) {
    .sideNav {
      width: 100%;
      overflow-y: scroll;
    }
  }
  