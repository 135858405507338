.proj{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}
.projContainer{
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
}
.projLeft{
    display: flex;
    justify-content: space-between;
    width: fit-content;
    gap: 10px;
    flex-wrap: wrap;
}
.projRight{
    padding: 20px;
    background-color: rgba(35, 35, 35, 0.53);
    border-radius: 10px;
    height: 250px;
    border: #9A9A9A 1px solid;
    display: flex;
    justify-content: center;
    width: inherit;
}
.projRight img{
    width: auto;
    height: 250px;
}
.projQuestion{
    background-color: rgba(35, 35, 35, 0.53);
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: #9A9A9A 1px solid;
    cursor: pointer;
    width: fit-content;
}
.projQuestion:hover{
    background-color: rgba(255, 255, 255, 0.26);
    border: #9A9A9A 1px solid;
    cursor: pointer;
}
.projAnswer{
    text-align: center;
    transition: opacity 0.1s ease-in;
    transition-timing-function: linear;
    font-family: 'PoppinsBold';
    font-size: 3rem;
}
.projCat{
    text-align: center;
    transition: opacity 0.1s ease-in;
    transition-timing-function: linear;
    width: inherit;
}
.projAnswer:hover{
    transform: none;
}
.straight{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 5px;
}
.link{
    background-color: #BA0000;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
}
@media only screen and (min-width: 768px) and (orientation: portrait){
    .welcomeH1{
        font-size: 4rem;
        width: 100%;
    }
    .projLeft{
        display: flex;
        justify-content: center;
        width: inherit;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 2rem;
    }
    .projRight{
        flex-direction: column;
        height: fit-content;
        margin-top: 1rem;
    }
    .straight{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 5px;
    }
    .projAnswer{
        width: fit-content;
        font-size: 4rem;
    }
    .projCat{
        font-size: 2rem;
    }
    .projRight img{
        width: 100%;
        height: auto;
    }
    .projQuestion{
        font-size: 1.6rem;
        padding: 15px 20px;
        margin-bottom: 0px;
        border-radius: 10px;
        border: #9A9A9A 1px solid;
        cursor: pointer;
        width: fit-content;
    }
}


@media screen and (max-width: 475px){
    .proj{
        padding-bottom: 2rem;
    }
    .projRight{
        flex-direction: column;
        height: fit-content;
        margin-top: 1rem;
    }
    .projAnswer{
        font-size: 1.8rem;
    }
    .projRight img{
        width: 100%;
        height: auto;
    }
    .projLeft{
        display: flex;
        justify-content: center;
        width: inherit;
        gap: 5px;
        flex-wrap: wrap;
    }
    .projQuestion{
        font-size: 12px;
        width: 20%;
        margin: 0;
    }
}