.innerrTop-faq{
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.faqContainer{
    display: flex;
    justify-content: space-evenly;
    width: 80dvw;
}
.faqLeft{
    width: 70%;
    margin-right:10px;
    display: flex;
    flex-direction: column;
    align-items: start;
}
 .faqLeft h2{
    text-align: left;
    font-size: 50px;
 }
 .faqLeft p{
    text-align: left;
    font-size: 1.1rem;
    
 }
 .button {
    display: inline-block;
    /* margin-right: 1200px; */
    
    margin-top: 30px;
  }
  
  .btnOpennForm {
    background-color: transparent;
    border: #9A9A9A 1px solid;
    color: white;
    padding: 10px 25px; 
    text-align: center; 
    text-decoration: none; 
    font-size: 16px; 
    cursor: pointer; 
    border-radius: 90px; 
    display: flex; 
    align-items: center;
    .btnOpennForm {
      background-color: transparent;
      border: #9A9A9A 1px solid;
      color: white;
      padding: 10px 25px; 
      text-align: center; 
      text-decoration: none; 
      font-size: 16px; 
      cursor: pointer; 
      border-radius: 90px; 
      display: flex; 
      align-items: center;
      justify-content: center; 
      transition: background-color 0.3s; 
    }
    
    .btnOpennForm:hover {
      background-color: white;
      color: black;
    }
    justify-content: center; 
    transition: background-color 0.3s; 
  }
  
  .btnOpennForm:hover {
    background-color: white;
    color: black;
  }
  
  .icon-circle {
    font-size:0.5em; 
    margin-left: 18px; 
    color: red;
    align-items: center;
  }
  .icon-arrow{
    color: white;
    background-color: red;
    padding: 5px 5px;
    border-radius: 90px;
    font-size:0.7em; 
    margin-left: 10px; 
    

  }



.content{
    max-height: 0;
    overflow: hidden;
    
}
.content.show{
    max-height: 900px;
    padding-top: 0.3rem;
   
}
.content.show p{
    padding: 15px;
    text-align:left;
}
.faq-question{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.faq-question h2{
    font-size: 20px;
    font-weight: 600;
}
.faq{
    padding: 0.5rem 0.1rem;
    margin-bottom: 0.5rem;
    max-width: 700px;
    border: #9A9A9A 1px solid;
    border-left: none; 
    border-right: none;
    border-top: none;
}
.plus-button {
    background-color: white;
    border: none; 
    padding: 7px 25px;
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 16px; 
    margin: 4px 2px;
    cursor: pointer; 
    border-radius: 20px; 
    transition: background-color 0.3s; 
  }
  .minus-button {
    background-color: transparent;
    border: #9A9A9A 1px solid;
    color: white;
    padding: 7px 25px;
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 16px; 
    margin: 4px 2px;
    cursor: pointer; 
    border-radius: 20px; 
    transition: background-color 0.3s; 
  }
  .minus-button:hover {
    background-color: white;
    color: black; 
  }

  @media only screen and (min-width: 768px) and  (orientation: portrait){
    .innerrTop-faq{
      margin-top: 15px;
    }
    .faqContainer{
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 80dvw;
      height: 65vh;
  }
  .faqLeft{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
  }
   .faqLeft h2{
      text-align:left;
      font-size: 60px;
   }
   .faqLeft p{
    text-align: left;
    font-size: 23px;
    margin-top: 10px;
   }
   .faqRight{
    margin-top: 90px ;
    width: 100%;
   }
   .faq-question h2{
    font-size: 24px;
    text-align: left;
    padding: 30px;
    width: 100%;
   }
   .plus-button {
    background-color: white;
    border: none; 
    padding: 7px 20px;
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 12px; 
    margin: 4px 2px;
    cursor: pointer; 
    border-radius: 20px; 
    transition: background-color 0.3s; 
  }
  .minus-button {
    background-color: transparent;
    border: #9A9A9A 1px solid;
    color: white;
    padding: 7px 20px;
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 12px; 
    margin: 4px 2px;
    cursor: pointer; 
    border-radius: 20px; 
    transition: background-color 0.3s; 
  }
  }

  @media screen and (max-width: 475px){

    .innerrTop-faq{
      margin-top: 15rem;
    }
    .faqContainer{
      display: flex;
      flex-direction: column;
      width: 80vw;
  }
  .faqLeft{
      width: 100%;
      margin-right:10px;
  }
   .faqLeft h2{
      text-align: left;
      font-size: 30px;
   }
   .faqLeft p{
    text-align: left;
    font-size: 16px;
   }
   .faq-question h2{
    font-size: 17px;
    text-align: left;
   }
   .plus-button {
    background-color: white;
    border: none; 
    padding: 7px 20px;
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 12px; 
    margin: 4px 2px;
    cursor: pointer; 
    border-radius: 20px; 
    transition: background-color 0.3s; 
  }
  .minus-button {
    background-color: transparent;
    border: #9A9A9A 1px solid;
    color: white;
    padding: 7px 20px;
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 12px; 
    margin: 4px 2px;
    cursor: pointer; 
    border-radius: 20px; 
    transition: background-color 0.3s; 
  }
  }
  
  


