@font-face {
   font-family: PoppinsBold;
   src: url("../font/Poppins-Bold.ttf");
 }
body{
   justify-content: center;
   align-items: center;
}
.gallery{
   justify-content: center;
}
.bckbtn{
   display: flex;
   text-decoration: none;
}
.bckbtn p{
   text-decoration: none;
   font-size: 16px;
   margin-top: 11px;
   margin-left: 10px;
}
.container6{
   height: 60vh;
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   margin-top: -10px;
   border-radius: 20px;
   overflow: hidden;

}
.picture{
   width:200px ;
   gap: 20px;
}
.picture1{
   width:200px ;
   gap: 60px;
}.picture2{
   width:200px ;
}
.picture8{
   width:200px ;
}
.img01{
   height: 200px;
   border-radius: 10px;
}
.img06{
  width: 200px;
   border-radius: 10px;
}
.img04{
   height: 200px;
   border-radius: 10px;
}
.imgclass{
   height: fit-content;
    position: relative;
    overflow: hidden;
    margin-top: 5px;
}
.imgclass img{
   width:190px ; 
   padding: 0;  
}

.imgclass .content-gallery{
   
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   height: 98.9%;
   width: 100%;
   background:rgba(41, 41, 41, 0.653) ;
   display: flex;
   border-radius: 17px;
   transition-delay:0s;
   padding-bottom: 0;

}
.imgclass:hover .content-gallery{
   display: none;
   
}
.imgclass5{
   height: fit-content;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
}
.imgclass5 img{
   width:190px ;
   height: 100%;   
}

.imgclass5 .content-gallery{
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   height: 98.9%;
   width: 100%;
   background:rgba(41, 41, 41, 0.653) ;
   display: flex;
   border-radius: 13px;
   padding-bottom: 0;
}
.imgclass5:hover .content-gallery{
   display: none;
}
.imgclass6{
   height: fit-content;
    position: relative;
    overflow: hidden;
    margin-top: -10px;
}
.imgclass6 img{
   width:190px ;
   height: 100%;   
}

.imgclass6 .content-gallery{
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   height: 100%;
   width: 100%;
   background:rgba(41, 41, 41, 0.653) ;
   display: flex;
   border-radius: 13px;
   padding-bottom: 0;
}
.imgclass6:hover .content-gallery{
   display: none;
}
.imgclass7{
   height: fit-content;
    position: relative;
    overflow: hidden;
    margin-top: -10px;
}
.imgclass7 img{
   width:190px ;
   height: 100%;   
}

.imgclass7 .content-gallery{
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   height: 100%;
   width: 100%;
   background:rgba(41, 41, 41, 0.653) ;
   display: flex;
   border-radius: 13px;
   padding-bottom: 0;
}
.imgclass7:hover .content-gallery{
   display: none;
}
.imgclass8{
   height: fit-content;
    position: relative;
    overflow: hidden;
    margin-top: 15px;
}
.imgclass8 img{
   width:190px ;   
}

.imgclass8 .content-gallery{
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   height: 98%;
   width: 100%;
   background:rgba(41, 41, 41, 0.653) ;
   display: flex;
   border-radius: 11px;
   
}
.imgclass8:hover .content-gallery{
   display: none;
   transition-delay:3s;
   
}

.container4{
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: ease all 0.5s;
   margin-top: 0px;
   width: 100%;
}
.whole{
   border-radius: 20px;
   overflow: hidden;
   position: relative;
   overflow: hidden;
   height:fit-content ;
   
}
.texttt{
   position: absolute;
   top: 0;
   left: 10%;
}
.textis{
   margin-top: 10px;
   width: fit-content;
}
.textis1{
   width: fit-content;
}
.textis2{
   width: fit-content;
}
.textis h1{
   font-family: PoppinsBold;
   opacity: 0.6;
   font-weight: 900;
   font-size: 70px;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   display: flex;
   margin-top: 30px;
   width: fit-content;
   height: fit-content;
}
.textis1 h1{
   font-family: PoppinsBold;
   opacity: 0.6;
   font-weight: 900;
   font-size: 70px;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   display: flex;
   margin-top: 160px;
   margin-left: 190px;
   width: fit-content;
   height: fit-content;
}
.textis2 h1{
   font-family: PoppinsBold;
   opacity: 0.6;
   font-weight: 900;
   font-size: 70px;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0; top: 0;
   z-index: 10000000;
   display: flex;
   margin-top: 290px;
   margin-left: 340px;
   width: fit-content;
   height: fit-content;
}
@media only screen and (min-width: 768px) and (orientation: portrait){
   .container6{
      height: 70vh;
      display: flex;
      flex-direction: column;
      width:100%;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: -10px;
      border-radius: 20px;
      overflow: hidden;
   
   }
   .picture{
     width: fit-content;
      display: flex;
      gap: 10px;
   }
   .picture1{
      width: fit-content;
      display: flex;
      gap: 10px;
   }.picture2{
      width: fit-content;
      display: flex;
      gap: 10px;
   }
   .picture8{
     
      display: none;
     
   }
   .imgclass img{
      width:290px ;   
   }
   .imgclass5  {
      margin-top: -10px;
      img{
      width:290px ;   
   }}
   .imgclass6  {
      margin-top: -50px;
      img{
      width:290px ;   
   }}  .imgclass7 {
      margin-top: -150px;
      img{
      width:290px ;   
   }}
   .imgclass8 img{
      width:290px ;   
   }
   .img01{
      height: 290px;
      border-top-left-radius:12px ;
      border-top-right-radius:12px ;
   }
   .img04{
      height: 280px;
      border-top-left-radius:12px ;
      border-top-right-radius:12px ;
   }
   .textis h1{
      font-family: PoppinsBold;
      opacity: 0.6;
      font-weight: 900;
      font-size: 80px;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top:100px ;
      z-index: 10000000;
      display: flex;
      margin-left: 0;
    
   }
   .textis1 h1{
      font-family: PoppinsBold;
      opacity: 0.6;
      font-weight: 900;
      font-size: 80px;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top:540px ;
      z-index: 10000000;
      display: flex;
      margin-left: 15px;
      
   }
   .textis2 h1{
      font-family: PoppinsBold;
      opacity: 0.6;
      font-weight: 900;
      font-size: 80px;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top:950px ;
      z-index: 10000000;
      display: flex;
      margin-left: 0;
      
   }
   
   .textis2{
      display: flex;
      width: 100%;
      
      justify-content: center;
      align-items: center;
   }
   .texttt{
      display: flex;
      align-items: center;
      height: fit-content;
      width: fit-content;
      justify-content: center;
      text-align: center;
      margin-top: 14px;
      margin-left: -10px;
   }
}


@media screen and (max-width: 475px){
   .textis h1{
      font-family: PoppinsBold;
      opacity: 0.6;
      font-weight: 900;
      font-size: 45px;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top:30px ;
      z-index: 10000000;
      display: flex;
      margin-left: 0;
   }
   .textis1 h1{
      font-family: PoppinsBold;
      opacity: 0.6;
      font-weight: 900;
      font-size: 45px;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top:140px ;
      z-index: 10000000;
      display: flex;
      margin-left: 15px;
      
   }
   .textis2 h1{
      font-family: PoppinsBold;
      opacity: 0.6;
      font-weight: 900;
      font-size: 45px;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top:250px ;
      z-index: 10000000;
      display: flex;
      margin-left: 0;
      
   }
   
   .textis2{
      display: flex;
      width: 100%;
      
      justify-content: center;
      align-items: center;
   }
   .texttt{
      display: flex;
      align-items: center;
      height: fit-content;
      width: fit-content;
      justify-content: center;
      text-align: center;
      margin-top: 14px;
      margin-left: -10px;
   }
   .whole{
      display: flex;
      justify-content: center;
      align-items: center;
      
      
   }
   .container6{
      overflow: hidden;
      
   }
   
 
   .imgclass img{
      width:190px ;   
   }
   .imgclass7 img{
      width:190px ;   
   }
   .imgclass8 img{
      width:190px ;   
   }
   .picture{
      display: none;
   }
   .picture8{
      display: none;
   }
   .picture1{
      width:160px ;
   }
   .picture1 img{
      width: 150px;
   }
   .picture2{
      width:160px ;
   }
   .picture2 img{
      width: 150px;
   }
   .bckbtn p{
      display: none;
   }
   .container4{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}