.aboutt{
    width: inherit;
    height: inherit;
    overflow: hidden;
} 
.innerrr{
  padding: 0 1rem;
display: flex;
flex-direction: column;
margin-top: 0rem;
justify-content: space-between;
align-items: center;
height: inherit;
}
.aboutmiddle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row{
    text-align: justify;
    padding: 0 20px;
    font-size: 16px;
}
.row :hover{
  transform: scale(1);
}
.aboutH1{
    font-family: 'Poppins';
    font-size: 3.3rem;
    width: 80%;
    text-align: center;
    font-weight: 500;
    line-height: 1;
    padding: 1.5rem 0 0 0;
    transition: 0.3s ease-out;
    transition-timing-function: linear;
  }
  .aboutItalic{
    font-weight: 700;
    font-style: normal;
    font-family: 'PoppinsBold';
  }
  /* Define CSS animation for sliding in from the right */
@keyframes aboutslideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Define CSS animation for sliding out to the left */
  @keyframes aboutslideOutToLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .slide-inRight {
    animation: aboutslideInFromRight 0.8s ease-in-out forwards;
  }
  
  .slide-outRight {
    animation: aboutslideOutToLeft 0.8s ease-in-out forwards;
  }
  
  @media only screen and (min-width: 768px) and  (orientation: portrait){
    .innerrr{
      padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    }
    .aboutmiddle{
      display: flex;
      flex-direction: column;
    }
    .aboutmiddle p{
      display: flex;
      flex-direction: column;
      font-size: 23px;
      padding-top: 5px;
      padding-bottom: 10px;
      text-align: justify;
      line-height: 50px;
     
    }
  }
  @media screen and (max-width: 475px){
    
    p:hover{
      transform: scale(1);
    }
    .row{
      padding: 0;
    }
    .aboutH1{
      font-family: 'Poppins';
      font-size: 1.8rem;
      width: 100%;
      text-align: center;
      font-weight: 500;
      line-height: auto;
      padding:  0;
      transition: 0.3s ease-out;
      transition-timing-function: linear;
    }
    .aboutmiddle{
      display: flex;
      flex-direction: column;
      p{
      font-size: 15px;
      padding-top: 5px;
      padding-bottom: 10px;
      text-align: justify;
      width: inherit;
     
    }
  }
  }