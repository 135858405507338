
.welcome {
    height: 100%;
    width: 100%;
}
.innerrTop {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.conContainer{
  margin-bottom: 100px;
  display: flex;
  justify-content: space-evenly;
  width: 80dvw;
}

/* .innerrTopRight {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
} */

  .welcomeleft{
    width: 100%;
    margin-right:10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    

  }
  .welcomeleft h1{
    text-align: left; 
    margin-left:0px;
    font-size: 60px;
 }
 .welcomeleft p{
  text-align: left;
  margin-top: 30px;
  font-size: 1.1rem;
  line-height: 2.1rem;
}

.btnOpennFormm {
  background-color: black;
  color: white;
  padding: 10px 25px; 
  text-align: center; 
  text-decoration: none; 
  font-size: 16px; 
  cursor: pointer; 
  border-radius: 90px; 
  display: flex; 
  align-items: center;
  justify-content: center; 
  transition: background-color 0.3s; 
}

.btnOpennFormm:hover {
  background-color: white;
  color: black;
}
 





.innerrC {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    justify-content: space-between;
    align-items: center;
    height: inherit;
}

.contactH1 {
    font-family: 'Poppins';
    font-size: 2.5rem;
    width: 60%;
    text-align: left;
    font-weight: 500;
    line-height: 1;
    padding: 0 0 0 0;
    transition: 0.3s ease-out;
    transition-timing-function: linear;
}

.italic {
    font-weight: 700;
    font-style: normal;
    font-family: 'PoppinsBold';
}

.goBackLink {
    display: flex;
    text-decoration: none;
}

.goBackText {
    margin-left: 3px;
    font-size: 25px;
    color: white;
}

.inputFields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2rem;

    color: #9A9A9A;
}

.inputField {
    width: 500px;
    padding: 26px  0px;
    border: 1px;
    border-bottom: 1px solid #9A9A9A;
    background-color: transparent;
    color: #9A9A9A;
    outline: none;
    text-align:start;
    font-size: 15px;
}
.inputFieldlast{
    width: 500px;
    padding: 26px  0px;
    border: 1px;
    border-bottom: 1px solid #9A9A9A;
    background-color: transparent;
    color: #9A9A9A;
    outline: none;
    text-align:start;
    font-size: 15px;
}


.aboutUs {
    text-align: left;
    padding-top: 18px;
    width: 100%; 
    font-size: 15px;
    margin-bottom: 0;
    padding-bottom: 0;
    color: #FFFFFF;
}
.aboutUs:hover{
    transform: scale(1);
}


.icon {
    margin-right: 10px; 
    width: 30px; 
    height: auto; 
    cursor: pointer;
}

.getInTouchButton {
    background: none;
    color: white;
    border: none;
    border-radius: 50px;
    
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    
}




.lefttext1 {
    width: 100%;
    display: flex;
    justify-content: flex-end;

  }
  
  .lefttext2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
   
  }
  
  .innerrback {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
 
  }
  
  .forwardbutton {
    display: flex;

    font-size: 14px;
  }


  .goBackLink{
    margin-right: 2px;
  }
  

  .twitter{
    margin-top : 20px;
    border: .5px solid #8D8D8D;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: none;
    background-color: transparent;
    padding: 5px 10px;
    color: #fff;
    
  }
  .twitter:active, .twitter:focus {
    border: .1px solid #BA0000;
    background-color: #BA0000;
    color: white, 
  }
  
  .twitterr{
    margin-top : 20px;
    border: .5px solid #8D8D8D;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    background-color: none;
    background-color: transparent;
    padding: 5px 10px;
    color: #fff;
    
  }
  .twitterr:active, .twitterr:focus {
    border: .1px solid #BA0000;
  }
  
  
  

  .email-contact{
    display: flex;
    flex-direction: column;
    
    justify-content: flex-start;
  
   
  }

  .imageIcons {
    display: flex;
    color: #8D8D8D;
    gap: 5px;
    
}

.contact-section{
   
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    text-align: left;
    margin: 0;
    outline: 5px solid green;
    
}
.formContainer{
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;
}
.sect{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    outline: 1px solid red;
}
.joinBtnnn{
  display: flex;
  align-items: center;
  margin-left: -2rem;
}
.circleLongg{
  background-color: #BA0000;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: -2rem;
  transition: ease all 0.5s;
}
.circleLongg.active{
  width: 90px;
  margin-right: -5rem;
  transition: ease all 0.5s;
}
/* .btnOpenForm000{

} */
.containerr99{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease all 0.5s;
  margin-top:0.7rem;
  margin-left: 1rem;
}
.lefttext{
    /* text-align: right; */
    margin-bottom: 5px;
}
  /* #8D8D8D */
  @media only screen and (min-width: 768px) and  (orientation: portrait){
    .conContainer{
      display: flex;
      flex-direction:column ;
      justify-content:flex-start;
      width: 100%;
      height: fit-content;
    }
    .innerrC{
        
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      margin-top: 0rem;
      justify-content: space-between;
      align-items: center;
      height: inherit;
  }
    .welcomeleft{
      width: 60%;
      margin-right:10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      line-height: 30px;
      margin-bottom:100px;
    }
    .conRight{
      width: 100%;
     
      display: flex;
      flex-direction: column;
     align-items: flex-end;
      justify-content: flex-end;
      line-height: 30px;
      margin-bottom: 250px
    }
    .formContainer{
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: center;
      width: 100%;
      align-items: flex-end;
  }
  .containerr99{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease all 0.5s;
    margin-top:0.7rem;
    margin-right: 230px;
    
  }
  
.inputFields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;

  color: #9A9A9A;
}

.inputField {
  width: 530px;
  padding: 46px  0px;
  border: 1px;
  border-bottom: 1px solid #9A9A9A;
  background-color: transparent;
  color: #9A9A9A;
  outline: none;
  text-align:start;
  font-size: 15px;
}
.inputFieldlast{
  width: 530px;
  padding: 26px  0px;
  border: 1px;
  border-bottom: 1px solid #9A9A9A;
  background-color: transparent;
  color: #9A9A9A;
  outline: none;
  text-align:start;
  font-size: 15px;
}

  }

  @media screen and (max-width: 475px) {

     .conContainer{
        display: flex;
        flex-direction: column;
      }
      .innerrC{
        
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        margin-top: 0rem;
        justify-content: space-between;
        align-items: center;
        height: inherit;
    }
      .backbutton{
        width: 35px;
        margin-right: 10px;
      }
      .innerrTopRight{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .forwardButtonContainer {
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-right: 10px; 
        font-size: 14px;
      }
      .innerrTop{
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 10px;
  } 
  .welcomeleft{
    gap: -50px;
    h1{
      font-size: 45px;
    }
    p{
      font-size: 17px;
      line-height: 29px;
    }
  }
  .buttonn{
    margin-bottom: 20px;
    p{
      font-size: 13px;
    }
  }
   .italic {
    font-weight: 500;
    font-style: normal;
    font-family: 'PoppinsBold';
    font-size: 22px;
}
.inputFields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2rem;
    color: #9A9A9A;
    width: auto;
}
.inputField {
    width: 342px;
    padding:  22px 0px;
    border: 1px;
    border-bottom: 1px solid #9A9A9A;
    background-color: transparent;
    color: #9A9A9A;
    outline: none;
    text-align:start;
    font-size: 15px;
    
}
.inputFieldlast {
  margin-top: 30px;
    width: 342px;
    padding:  22px 0rem 80px;
    border: 1px;
    border-bottom: 1px solid #9A9A9A;
    background-color: transparent;
    color: #9A9A9A;
    outline: none;
    font-size: 15px;
}
    
.imageIcons { 
  margin-top: 10px;
    flex-wrap: wrap;
    Gap: 1px;
    color: #8D8D8D;
  }

.icon {
    margin-right: 10px; 
    width: 30px; 
    height: auto; 
    cursor: pointer;
}
  .twitter{
    margin-top : 5px;
    border: .5px solid #8D8D8D;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    padding: 2px 5px;
    color: #8D8D8D;
    margin-right: 2px;
    
  }
  .twitterr{
    margin-top : 5px;
    border: .5px solid #8D8D8D;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: none;
    background-color: transparent;
    padding:12px 25px;
    color: #8D8D8D;
  }
  .twitterr:active, .twitterr:focus {
    border: .1px solid #BA0000;
  }
  
  
  /* .innerr{
    justify-content: center;
    padding: 0px 12px;
    overflow-x: scroll;
  } */
  .formContainer{

    align-items: flex-end;
    justify-content: space-evenly;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
  .lefttext{
   display: none;
  }

  .forwardbutton {
    display: flex;
    margin-left:-200px;
    font-size: 14px;
    justify-content: center;
  }

  .sendEmailLink {
    text-align: center;
    text-decoration: underline;
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left:-240px;
    justify-content: center;
  }
  .circleLongg{
    display: none;
  }
  .getInTouchButton {
    
    background-color: #BA0000;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 10px;
    padding: 10px 17px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.getInTouchButton:hover {
    background-color: #a60101; 
}
.innerrback {
   display: flex;
   margin-bottom: -300px;
   margin-left: -80px;
    align-items: center;
    justify-content:right;
    font-size: 14px;
 
  }
  
}
