
.body{
    display: flex;
    align-items: center;
    justify-content: center;
}
.processmainn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.processmain{
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    margin-inline: 0px;
   
    margin-inline: 50px;
}
.hugxhug{
    
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0;
}
.hug{
    display: flex;
    flex-direction: column;

    width:260px;
    height:120px;
    padding: 30px 0px 0px 0px;

    /* width:300px;
    height:auto;
    padding: 30px 0px 30px 0px; */

    border-radius: 20px;
    border: 1px solid #737373;
    background:#343434;

    p{
        font-size: 16px;
    }
}
.liness{
    margin: 0;
    padding: 0;
}
.liness1,.liness2, .liness3{
    width: 400px;
}
.iconNumber{
    display: flex;
    gap: 120px;
    margin-inline: 30px;
    align-items: flex-start;
   
   
}
.iconNumber h1{
    color: #464646;
    font-weight: 700;
    font-style: normal;
    font-family: 'PoppinsBold';
    font-size: 70px;  
    margin-top: -30px;
}
.iconNumber img{
    height: 35px;
    width: 50px;
}
.hug p{
    margin-inline: 30px;
    text-align: left;
    font-size: 16px;
}


@media only screen and (min-width: 768px) and  (orientation: portrait){
    .processmainn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .processmain{
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0px;
        margin-inline: 0px;
       
        margin-inline: 50px;
    }
    .hugxhug{
        
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin: 0;
    }
    .hug{
        display: flex;
        flex-direction: column;
    
        width:250px;
        height:280px;
        padding: 30px 0px 0px 0px;
    
        /* width:300px;
        height:auto;
        padding: 30px 0px 30px 0px; */
    
        border-radius: 20px;
        border: 1px solid #737373;
        background:#343434;
    
        p{
            font-size: 16px;
        }
    }
    .liness{
        margin: 0;
        padding: 0;
    }
    .liness1,.liness2, .liness3{
        width: 350px;
    }
    .iconNumber{
        display: flex;
        gap: 120px;
        margin-inline: 30px;
        align-items: flex-start;
       
       
    }
    .iconNumber h1{
        color: #464646;
        font-weight: 700;
        font-style: normal;
        font-family: 'PoppinsBold';
        font-size: 70px;  
        margin-top: -30px;
    }
    .iconNumber img{
        height: 35px;
        width: 50px;
    }
    .hug p{
        margin-inline: 30px;
        text-align: left;
        font-size: 23px;
        margin-top: 80px;
    }
}


@media screen and (max-width: 475px){
    
    
    .liness {
        display: none;
    }
    .hug{
        width: 130px;
        height: 100px;
    }
    .iconNumber{
        display: flex;
        gap: 20px;
        margin-inline: 10px;
        align-items: flex-start;
       margin-bottom: 10px;
       
    }
    .iconNumber h1{
        color: #464646;
        font-weight: 700;
        font-style: normal;
        font-family: 'PoppinsBold';
        font-size: 35px;  
        
    }
    .iconNumber img{
        height: 35px;
        width: 35px;
        margin-top: -15px;
    }
    .hug p{
        margin-inline: 15px;
        text-align: left;
        font-size: 12px;
    }
    .processmain{
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-inline: 0px;
    }
    
    
}


