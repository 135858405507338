.cultureContainer{
    display: flex;
    flex-wrap: wrap;
    width: inherit;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.cultureBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 280px;
    padding: 10px;
}
.cultureBlock p{
    font-size: 15px;
    padding: 5px 0;
}
.cultureBlock img{
    padding: 5px 0;
    width: 40px;
}
@media only screen and (min-width: 768px) and  (orientation: portrait){
    .cultureContainer{
        display: grid;
       grid-template-columns: 1fr 1fr;
        flex-wrap: wrap;
        width: inherit;
        gap: 100px;
        align-items: center;
        justify-content: center;
    }
    .cultureBlock{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 380px;
        padding: 10px;
    }
    .cultureBlock h3{
        font-size: 25px;
    }
    .cultureBlock p{
        font-size: 20px;
        line-height: 28px;
        padding: 10px 0;
    }
    .cultureBlock img{
        padding: 5px 0;
        width: 60px;
    }  
}
@media screen and (max-width: 475px){
    .cultureContainer{
        padding-bottom: 2rem;
    }
}