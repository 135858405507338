.teamImage img {
  object-fit: contain;
  /* object-position: center; */

  

  width: 60%;

}

.backText {
  padding-left: 0.25rem;
  font-size: 0.75rem;
  white-space: nowrap;
}
.teamDirectionButtons{
    display: flex;
    column-gap: 10px;
    margin: 45px 0;
    align-self: flex-end;
    margin-top: -20px;
  
}

.teamImage {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -22px;
}

.teamName {
  font-size: 1.25rem;
  font-weight: 700;
}

.teamRole {
  font-size: 15px;
  font-weight: 500;
  padding-top: 10px;
}

.socialLinks {
  display: flex;
  column-gap: 10px;
  justify-content: center;
  padding-top: 10px;
}

.socialLinks img {
  width: 1.5rem;
}



.innerr-team {
  overflow: hidden;
} 


@media (max-width: 768px) {

    

    .teamImage img {
        width: 60%;
      }
      .teamImage{
        margin: 0;
       display: flex;
       align-items: flex-end;

      }
      .welcomeh1{
        font-family: 'Poppins';
        font-size: 1.9rem;
        width: 100%;
        text-align: center;
        font-weight: 500;
        line-height: auto;
        padding:  0;
        transition: 0.3s ease-out;
        transition-timing-function: linear;

        .italic{
          font-size: 1.9rem;
        }
      }

      .slickPrev{
        width: 20px;
        height: 20px;
      }
      .slickNext{
        width: 20px;
        height: 20px;
      }
}

  

  .team .testimonialCardSection {
    padding-bottom: 0;
  }

  .teamImage img {
    width: 70%;
  }
  @media only screen and (min-width: 768px) and  (orientation: portrait){
    
    .teamImage{
      margin: 0;
      
     display: flex;
     align-items: flex-end;

    }
   .slickPrev{
      width: 20px;
      height: 20px;
    }
    .slickNext{
      width: 20px;
      height: 20px;
    }




.team .testimonialCardSection {
  padding-bottom: 0;
}

.teamImage img {
  width: 100%;
}
  }