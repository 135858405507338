*{
    font-family: Silka, Poppins, PoppinsBold, PoppinsSemiBold;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }
  @font-face {
    font-family: Silka;
    src: url("../font/silka-regular.ttf");
  }
  @font-face {
    font-family: Poppins;
    src: url("../font/Poppins-Regular.ttf");
  }
  @font-face {
    font-family: PoppinsBold;
    src: url("../font/Poppins-Bold.ttf");
  }
  @font-face {
    font-family: PoppinsSemiBold;
    src: url("../font/Poppins-SemiBold.ttf");
  }
  .preload{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #404040;
    background-image: url(../img/back.png);
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s ease-in 0.2s;
    transition-timing-function: ease-out;
  }
.preloadContent{
    width: 55vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .loaded{
    opacity: 0;
    display: none;
  }
  .bodyLoaded{
    opacity: 1;
    transition: all .6s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
  }
  .butn{
    background-color: #BA0000;
    border-radius: 30px;
    width: 20vw;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Silka;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 2rem;
    color: white;
    margin-bottom: 0.5rem;
}
  .quote{ 
    display: flex;
    justify-content: center;
    align-items: center;
}
.counter{
    position: absolute;
    top: 60%;
    left: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 100;
    font-size: 100px;
    font-style: italic;
    color: rgba(100, 100, 100, 0.5);;
}
  .cursor {
    position: fixed;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    left: -100px;
    top: 50%;
    mix-blend-mode: difference;
    background-color: rgba(223, 223, 223, 0.73);
    z-index: 10000;
    height: 47px;
    width: 47px;
    transition: all 180ms ease-out;
  }
  h1:hover {
    transform: scale(1.1);
  }
  p:hover {
    transform: scale(1.1);
  }
  a{
    color: #fff;
  }
  body{
    background-color: #2e2e2e;
    background-image: url(../img/background.png);
    background-size: cover;
    background-position: top;
    margin: 0rem 6rem 0 6rem;
    color: white;
    text-align: center;
    padding: 5rem 0 0 0;
    overflow: hidden;
  }
  nav{
    padding: 9px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 85%;
}
nav p{
    font-size: 16px;
  }
.navMenu{
    height: 100dvh;
    width: 43%;
    position: absolute;
    top: 0%;
    right: -60%;
    background-image: url(../img/navImage1.png);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 10;
    transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    transition-timing-function: ease-out;
}
.navMenu.active{
    right: -10%;
}
.navTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 40px 30px;
}
.navTop img{
    cursor: pointer;
}
.navRight{
    display: flex;
    justify-content: center;
  }
  .topText{
    font-size: 14px;
    opacity: 0.5;
}
.navLinksContainer{
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin: 20px 30px;
    padding:  15px 0px;
    cursor: pointer;
    border-bottom: 2px solid rgba(255, 255, 255, 1);
    opacity: 0.3;
}
.navLinksContainer.active{
    opacity: 1;
}
.navLinksContainer:hover{
    opacity: 1;
}
.subLink{
    font-size: 14px;
    padding: 5px 0;
}
.navLink{
    font-family: 'Poppins';
    font-size: 20px;
    list-style: none;
    font-weight: 700;
}
.bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    position: fixed;
    bottom: 0;
    width: 85%;
}
.imgContain{
    display: flex;
    gap: 20px;
}
.image{
    width: 30px;
}
.btn{
    background-color: #222222;
    padding: 9px 25px;
    border-radius: 50px;
    border: 1px solid #ffffff48;
    color: #fff;
    font-family: Poppins;
    font-weight: 300;
    font-size: 12px;
}
.main{
    height: 81dvh;
    margin-top: -0.5rem;
    margin-bottom: 4.5rem;
}
.bodie{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    outline: 2px solid rgba(87, 87, 87, 0.5);
    background-image: url(../img/background1.png);
    background-position: right; 
    background-size: cover;
  }
#container{
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: ease all 0.5s;
}
.containerNav{
    cursor: pointer;
    transition: ease all 0.5s;
    padding: 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerNav2{
    cursor: pointer;
    transition: ease all 0.5s;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerNav3{
    cursor: pointer;
    transition: ease all 0.5s;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle.active{
    width: 250px;
    margin-right: -13.5rem;
    transition: ease all 0.5s;
}
.circle{
    background-color: #222222;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: -2rem;
    transition: ease all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circleNav{
    background-color: #222222;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    transition: ease all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circleNav2{
    background-color: #222222;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: ease all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: #ffffff60 solid 0.5px;
}
.circleNav.active{
    width: 190px;
    margin-right: -9.8rem;
    justify-content: flex-start;
    padding: 0 15px;
}
@media only screen and (min-width: 768px) and  (orientation: portrait){
  
}
@media screen and (max-width: 475px){
  body{
    margin: 0rem 1rem;
    overflow: hidden;
  }
 .logo{
  width: 80px;
  height: auto;
 }
 .logo1{
  width: 20px;
  height: auto;
 }
 .clock{
  display: none;
 }
 #clock{
  font-size: 13px;
  outline: 1px solid red;
 }
 .main{
  height: 100svh;
  margin-top: -1.5rem;
  margin-bottom: 0rem;
}
.bodie{
  display: flex;
  flex-direction: column;
  height: 81svh;
  align-items: center;
  justify-content: space-around;
  outline: 2px solid rgba(87, 87, 87, 0.5);
  background-image: url(../img/background1.png);
  background-position: right;
  background-size: cover;
  overflow-x: hidden;
}

.imgContain{
  display: flex;
  gap: 5px;
}
nav{
  width: 91%;
}
.navRight{
  display: flex;
  justify-content: flex-end;
}
.circleNav{
  background-color: #222222;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: ease all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerNav{
  cursor: pointer;
  transition: ease all 0.5s;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;
  position: fixed;
  bottom: 0;
  width: 92%;
}
#container{
  display: none;
}
.btn{
  background-color: #222222;
  padding: 9px 25px;
  border-radius: 50px;
  border: 1px solid #ffffff48;
  color: #fff;
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
}
.cursor {
  display: none;
}
}