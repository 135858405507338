.valueContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.values{
    width: 60%;
}
.twin{
    display: flex;
    justify-content: space-evenly;
    padding: 2rem 0 0 0;
}
.single{
    width: 40%;
}
.valuesTop{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.valuesTop img{
    width: 30px;
    height: 100%;
}
@media only screen and (min-width: 768px) and  (orientation: portrait){
    .values{
        margin-top: -160px;
        width: 90%;
        p{
            text-align: justify;
            font-size: 25px;
            line-height: 48px;
        }
    }
    .twin{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 150px;
        padding: 2rem 0 0 0;
        margin-top: 150px;
    }
    .single{
        width: 65%;
        font-size: 25px;
        line-height: 45px; 
       
        p{
            font-size: 25px;
            text-align: justify;
            margin-top: 18px;
        }
    }
    .valuesTop{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        h2{
            font-size: 50px;
        }
    }

    .valuesTop img{
        width: 40px;
        height: 100%;
    }
}
@media screen and (max-width: 475px){
    .values{
        width: inherit;
    }
    .values p{
        text-align: justify;
        font-size: 16px;
    }
    .twin{
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 3rem;
    }
    .single{
        width: inherit;
    }
    .single p{
        font-size: 16px;
        text-align: justify;
    }
}