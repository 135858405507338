/* * {
    box-sizing: border-box;
} */

.starRating {
  padding-bottom: 13px;
}

.testimonialCardSection {
    /* margin-right: 10px; */
  padding-left: 0px;
  width: 90%;
  /* margin-top: 1rem; */
  /* display: absolute; */
}

.testimonialCard {
  width: 90% !important;
}

.slick-list:nth-of-type(3) {
  width: 90% !important;
}

.testimonialContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  /* height: 90%; */
  /* width: 100%; */
  height: 130px;
}

.innerrT {
    overflow: hidden;
    /* overflow: hidden; */
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    justify-content: space-between;
    align-items: center;
    height: inherit;
}

.innerrTop {
  display: flex;
}

.testimonialLogos {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  width: 80%;
  margin: 10px auto;
}
.testimonialLogos img{
  width: 120px;
}

.testimonialContent p {
  width: 100%;
  font-size: 14px;
  font-family: "Poppins";
  /* line-height: 22px; */
}

.testimonialClientDetails .name {
  font-size: 1rem;
  /* line-height: 30px; */
  padding-top: 15px;
}

.testimonialClientDetails .year {
  font-size: 0.7rem;
  /* line-height: 25px; */
}

.testimonialDirectionButtons {
  display: flex;
  column-gap: 10px;
  margin: 15px 0;
  align-self: flex-end;
  padding-bottom: 20px;
}

.innerrTop .backNav {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.testimonialDirectionButtons img {
  cursor: pointer;
}

@media (orientation: portrait){
  .testimonialContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    /* height: 90%; */
     width: 100%; 
    height: 430px;
  }
  
  .innerrT {
      overflow: hidden;
      /* overflow: hidden; */
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      margin-top: 0rem;
      justify-content: space-between;
      align-items: center;
      height: inherit;
  }
/*   
  .innerrTop {
    display: flex;
  } */
  
  .testimonialLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    width: 80%;
    margin: 10px auto;
  }
  .testimonialLogos img{
    width: 150px;
  }
  
  .testimonialContent p {
    width: 100%;
    margin-top: 20px;
    font-size: 1.09rem;
    font-family: "Poppins";
     line-height: 32px;
  }
}
@media (max-width: 800px) {
  .testimonialDirectionButtons img {
    cursor: pointer;
    width: 2rem;
  }

  .testimonialDirectionButtons {
    margin: 20px 0;
    margin-top: 20px;
  }
  .testimonialCardSection {
    /* margin-right: 10px; */
  padding-left: 0px;
  width: 100%;
  /* margin-top: 1rem; */
  /* display: absolute; */
}

.testimonialCard {
  width: 100% !important;
  height: 240px;
}

  .innerrTop .backNav {
    align-self: self-start;
  }

  /* .testimonialCardSection {
   
  } */

  .testimonialLogos {
    margin-bottom: 40px;
  }

  /* .testimonialCard { */
    /* height: 240px; */
  /* } */

  
}

@media screen and (max-width: 475px){
  .innerrT{
    height: fit-content;
  }
  .testimonialLogos {
    margin: 50px 0;
    gap: 10px;
  }
  .testimonialLogos img{
    width: 55px;
  }
  .testimonialCardSection{
    margin-top: -35px;
  }
  .testimonialContent {
    width: 100%;
    height: 100%;
  }
  .testimonialContent p {
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
    font-family: "Poppins";
     line-height: 24px;
  }
  .testimonialClientDetails .name {
    font-size:12px;
    /* line-height: 30px; */
   margin-top: -14px;
  }
  
  .testimonialClientDetails .year {
    font-size: 11px;
    margin-top: -10px;
  
  }
  .starRating {
    padding-bottom: 1px;
  }












}